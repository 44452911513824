import React, {ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {ThemeType} from 'types/Theme'

import * as iconsList from '@findhotel/atlas-assets'

import {Icon, IconSize} from '../Icon'

export type BadgeVariant =
  | 'info'
  | 'neutral'
  | 'special'
  | 'success'
  | 'warning'
  | 'danger'

export type BadgeSize = Extract<SizesType, 'xs' | 'sm' | 'md'>

interface Props {
  /** Content to be displayed in the Badge */
  children?: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Icon used instead of children font */
  iconName?: keyof typeof iconsList
  /** The size of the component */
  size?: BadgeSize
  /** The variation of styling the component will have */
  variant?: BadgeVariant
}

interface StyleProps {
  iconName: Props['iconName']
  size: BadgeSize
  variant: BadgeVariant
}

interface SizeMapItem {
  iconSize: IconSize
  iconPadding: number
  size: number
}

const sizeMap: Record<string, SizeMapItem> = {
  xs: {iconSize: 'xs', iconPadding: 2, size: 16},
  sm: {iconSize: 'sm', iconPadding: 2, size: 20},
  md: {iconSize: 'sm', iconPadding: 4, size: 24}
}

const withColor = ({theme, variant}: StyleProps & {theme: ThemeType}) => {
  const styles = theme.colors.badge[variant]
  return css`
    color: ${styles.content};
    background-color: ${styles.background};
  `
}

const withSize = ({size}: StyleProps) => css`
  height: ${sizeMap[size].size}px;
  min-width: ${sizeMap[size].size}px;
`

const withPadding = ({
  theme,
  iconName,
  size
}: StyleProps & {theme: ThemeType}) => css`
  padding: 0
    ${iconName ? sizeMap[size].iconPadding : theme.layout.spacing.s200}px;
`

export const Wrapper = styled.div<StyleProps>(
  ({theme}) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-radius: ${theme.layout.radius.rounded}px;
    ${theme.typography.text.labelXS}
  `,
  withColor,
  withSize,
  withPadding
)

export const Badge = ({
  children,
  iconName,
  size = 'xs',
  variant = 'info',
  className
}: Props) => (
  <Wrapper
    iconName={iconName}
    size={size}
    variant={variant}
    className={className}
  >
    {iconName ? (
      <Icon name={iconName} size={sizeMap[size].iconSize} />
    ) : (
      children
    )}
  </Wrapper>
)
