import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemePropType, ThemeType} from 'types/Theme'

import {InputButtonStyledContainer} from '@daedalus/atlas/InputButton'

export const IconWrapper = styled('div')``

const formButtonFocusHoverStyles = ({theme}: ThemePropType) => css`
  background: ${theme.colors.border.neutral.c000};

  ${InputButtonStyledContainer} {
    background: initial;
  }
`

const formButtonHoverStyles = ({theme}: ThemePropType) => css`
  ${InputButtonStyledContainer} {
    border-color: ${theme.colors.border.neutral.c500};
  }
`

export const formButtonFocusBorderStyles = ({theme}: ThemePropType) => css`
  border-color: ${theme.colors.border.interactive.c500} !important;
  box-shadow: 0px 0px 0px 1px ${theme.colors.border.interactive.c500} !important;
`

export const formButtonFocusIconStyles = ({theme}: ThemePropType) => css`
  color: ${theme.colors.content.interactive.c500} !important;
`

const formButtonFocusStyles = ({theme}: ThemePropType) => css`
  ${InputButtonStyledContainer} {
    ${formButtonFocusBorderStyles({theme})}
  }
  ${IconWrapper} {
    ${formButtonFocusIconStyles({theme})}
  }
`

export const formButtonBaseStyles = ({
  theme,
  borderRadius
}: {
  theme: ThemeType
  borderRadius?: number
}) => css`
  border-radius: ${borderRadius ? borderRadius : theme.layout.radius.lg}px;

  ${InputButtonStyledContainer} {
    border-radius: ${borderRadius ? borderRadius : theme.layout.radius.lg}px;
  }

  ${IconWrapper} {
    color: ${theme.colors.content.neutral.c600};
  }

  :focus,
  :hover {
    ${formButtonFocusHoverStyles({theme})}
  }
  :hover {
    ${formButtonHoverStyles({theme})}
  }
  :focus {
    ${formButtonFocusStyles({theme})}
  }
`

export const formButtonComposedStyles = ({theme}: {theme: ThemeType}) => css`
  ${formButtonFocusHoverStyles({theme})}
  ${formButtonFocusStyles({theme})}
`
