import {css} from '@emotion/react'
import styled from '@emotion/styled'

export const StyledPopper = styled('div')(
  ({theme}) =>
    css`
      border-radius: ${theme.layout.radius.lg}px;
      background-color: ${theme.colors.background.neutral.c000};
      box-shadow: ${theme.shadows.floating};
      z-index: 4;
      overflow: hidden;
    `
)
