import {ReactNode, useLayoutEffect, useRef} from 'react'
import {createPortal} from 'react-dom'

const portalRoot = document.body as HTMLBodyElement

interface Props {
  children: ReactNode
}

const createPortalElement = () => {
  const el = document.createElement('div')
  return el
}

export const Portal = ({children}: Props) => {
  const elRef = useRef<HTMLDivElement | null>(null)
  if (!elRef.current) elRef.current = createPortalElement()

  useLayoutEffect(() => {
    portalRoot.appendChild(elRef.current!)
    return () => {
      portalRoot.removeChild(elRef.current!)
    }
  }, [])

  return createPortal(children, elRef.current)
}
