import {Size} from '../dom/hooks/useWindowSize'

/**
 *
 * @param window The global window object
 * @returns an object containing the innerWidth and innerHeight of the window
 */
export const getWindowSize = (): Size => ({
  width: window.innerWidth,
  height: window.innerHeight
})
