import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

export const HEADER_HEIGHT = 65

const headerStyles = ({
  theme,
  hasBoxShadow
}: {
  theme: ThemeType
  hasBoxShadow?: boolean
}) => css`
  background: ${theme.colors.background.neutral.c000};
  box-shadow: ${hasBoxShadow ? theme.shadows.action : 'none'};
  position: relative;
  z-index: 1;
  min-height: ${HEADER_HEIGHT}px;
  display: flex;
`

export const Header = styled('div')<{
  hasBoxShadow?: boolean
}>(headerStyles)

export const HeaderWrapper = styled('div')<{showCloseButton: boolean}>(
  ({theme, showCloseButton}) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: ${theme.layout.spacing.s400}px
      ${showCloseButton
        ? theme.layout.spacing.s900
        : theme.layout.spacing.s500}px;
    min-height: ${HEADER_HEIGHT}px;
  `
)

export const HeaderContent = styled('div')(
  ({theme}) => css`
    flex-grow: 1;
    text-align: center;
    ${theme.typography.text.titleM};
    color: ${theme.colors.content.neutral.c950};
  `
)

export const OverlayBody = styled('div')`
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`

export const OverlayContent = styled('div')(
  () => css`
    flex: 1;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  `
)

export const OverlayFooter = styled('div')(
  ({theme}) => css`
    box-shadow: ${theme.shadows.fixedReverse};
  `
)
