import React, {ReactElement, ReactNode} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {SizesType} from 'types/Sizes'
import {ThemeType} from 'types/Theme'

import {Text} from '../../designSystem/Text'

export type TagVariant =
  | 'info'
  | 'neutral'
  | 'special'
  | 'success'
  | 'warning'
  | 'accent'

export type TagSize = Extract<SizesType, 'sm' | 'md'>

type Props = {
  /** Content to be displayed in the Tag */
  children?: ReactNode
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** The button click handler */
  onClick?: () => void
  /** The size of the tag */
  size?: TagSize
  /** An Icon to display on the left side of the input. Inverted direction for RTL  */
  iconStart?: ReactElement
  /** An Icon to display on the right side of the input. Inverted direction for RTL */
  iconEnd?: ReactElement
  /** The variation of styling the component will have */
  variant?: TagVariant
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Extra element to be displayed on the right side (left if RTL) */
  annotation?: ReactNode
}

interface StyleProps {
  size: TagSize
  variant: TagVariant
}

const base = ({theme}: {theme: ThemeType}) => css`
  display: inline-flex;
  align-items: center;
  border-radius: ${theme.layout.radius.md}px;
  gap: ${theme.layout.spacing.s200}px;
  transition: color 0.2s, background-color 0.2s;
`

const withColor = ({theme, variant}: StyleProps & {theme: ThemeType}) => {
  const styles = theme.colors?.tag?.[variant]
  return css`
    color: ${styles.content};
    background-color: ${styles.background};
  `
}

const withSize = ({theme, size}: StyleProps & {theme: ThemeType}) => {
  switch (size) {
    case 'md': {
      return css`
        ${theme.typography.text.labelS}
        padding: ${theme.layout.spacing.s250}px ${theme.layout.spacing.s300}px;
        min-height: ${theme?.layout.spacing.s700}px;
      `
    }
    default: {
      return css`
        ${theme.typography.text.labelXS};
        padding: ${theme.layout.spacing.s200}px ${theme.layout.spacing.s300}px;
        min-height: ${theme?.layout.spacing.s600}px;
      `
    }
  }
}

export const BasicTagWrapper = styled('span')(base, withSize, withColor)

type BackgroundVariant = Extract<
  keyof ThemeType['colors']['background'],
  TagVariant
>

export const Wrapper = styled.div<{
  variant: TagVariant
}>(({theme, variant}) => {
  const colorVariant = variant as BackgroundVariant

  return css`
    display: inline-flex;
    align-items: center;
    border-radius: ${theme.layout.radius.md}px;
    background-color: ${theme.colors.tag[colorVariant].background};
    color: ${theme.colors.tag[colorVariant].content};
    transition: background-color 0.2s, color 0.2s;
    ${AnnotationLabel} {
      background-color: ${theme.colors.tag[colorVariant].annotationBackground};
      color: ${theme.colors.tag[colorVariant].annotationContent};
    }
    ${BasicTagWrapper} {
      border-radius: ${theme.layout.radius.md}px 0 0 ${theme.layout.radius.md}px;
    }
  `
})

export const AnnotationLabel = styled.div<{
  hasAnnotation?: boolean
  variant: TagVariant
}>(({theme, hasAnnotation, variant}) => {
  const styles = theme.colors?.tag?.[variant]

  return css`
    display: flex;
    position: relative;
    align-items: center;
    align-self: stretch;
    padding-top: ${theme.layout.spacing.s200}px;
    padding-bottom: ${theme.layout.spacing.s200}px;
    padding-right: ${theme.layout.spacing.s300}px;
    padding-left: ${theme.layout.spacing.s400}px;
    border-radius: 0 ${theme.layout.radius.md}px ${theme.layout.radius.md}px 0;
    margin-left: ${hasAnnotation ? -theme.layout.spacing.s100 : 0}px;
    transition: background-color 0.2s, color 0.2s;

    &:before {
      content: '';
      position: absolute;
      width: 0;
      height: 0;
      top: 0;
      left: 0;
      border-width: 12px 0 12px 5px;
      border-color: transparent transparent transparent ${styles.background};
      border-style: solid;
      transition: border-color 0.2s;
    }
  `
})

export const BasicTag = ({
  children,
  dataId,
  onClick,
  size = 'sm',
  iconStart,
  iconEnd,
  variant = 'info',
  className
}: Props) => (
  <BasicTagWrapper
    data-id={dataId}
    variant={variant}
    size={size}
    onClick={onClick}
    className={className}
  >
    {iconStart}

    {Boolean(children) && <span>{children}</span>}

    {iconEnd}
  </BasicTagWrapper>
)

export const Tag = ({
  annotation,
  children,
  className,
  dataId,
  iconEnd,
  iconStart,
  onClick,
  size = 'sm',
  variant = 'info'
}: Props) => {
  const hasAnnotation = Boolean(annotation)
  if (!hasAnnotation) {
    return (
      <BasicTag
        className={className}
        dataId={dataId}
        iconEnd={iconEnd}
        iconStart={iconStart}
        onClick={onClick}
        size={size}
        variant={variant}
      >
        {children}
      </BasicTag>
    )
  }

  return (
    <Wrapper variant={variant}>
      <BasicTag
        className={className}
        dataId={dataId}
        iconEnd={iconEnd}
        iconStart={iconStart}
        onClick={onClick}
        size={size}
        variant={variant}
      >
        {children}
      </BasicTag>
      <AnnotationLabel hasAnnotation={hasAnnotation} variant={variant}>
        <Text variant="labelXS">
          <span dir={'ltr'}>{annotation}</span>
        </Text>
      </AnnotationLabel>
    </Wrapper>
  )
}
