import React, {ChangeEvent, ReactNode, useState} from 'react'
import {css} from '@emotion/react'
import styled from '@emotion/styled'
import {ThemeType} from 'types/Theme'

import {
  appearanceNone,
  baseLayout,
  CaptionWrapper,
  CaratWrapper,
  EndIconsWrapper,
  InputBaseSize,
  InputWrapper,
  StartIconsWrapper,
  TextTransform,
  ValidationBadge,
  withBackground,
  withBorder,
  withDisabled,
  withIconPadding,
  withInvalidState,
  withRadius,
  withShadows,
  withSize,
  withTextTransform,
  withTypography
} from '../helpers/InputBase'
import {Icon} from '../Icon'
import {Text} from '../Text'

interface Props {
  /** Field autocomplete id */
  autoComplete?: string
  /** Message to display below input. Changes color along with validation highlights. */
  caption?: ReactNode
  /** The options of the select element */
  children: ReactNode
  /** Pass through classname to allow styles overrides */
  className?: string
  /** Identify the element for selection in integration tests, FullStory, etc. */
  dataId?: string
  /** Whether the input is disabled */
  disabled?: boolean
  /** Whether the input has an error */
  hasError?: boolean
  /** An Icon to display on the left side of the input for LTR languages. Inverted on RTL */
  icon?: JSX.Element
  /** ID of this component, passed down by the controlling parent component */
  id?: string
  /** Whether the input is rounded */
  isRounded?: boolean
  /** Whether the user has interacted with this input */
  isTouched?: boolean
  /** Name of this component, passed down by the controlling parent component */
  name?: string
  /** An onBlur callback passed down by the controlling parent component */
  onBlur?: React.FocusEventHandler<HTMLSelectElement>
  /** An onChange callback passed down by the controlling parent component */
  onChange?: (value: string) => void
  /** Callback that fires when the input is clicked */
  onClick?: () => void
  /** Content to be used as the input placeholder */
  placeholder?: string
  /** Formik function for setting a specific field's value. Used to update the value without sending an HTML event. */
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean) => void
  /** The size of the input */
  size?: InputBaseSize
  /** CSS text-transform style to apply to the input field */
  textTransformType?: TextTransform
  /** The value passed down by the controlling parent component */
  value?: string
  /** Whether the input shows or not the validation icon */
  withValidationIcon?: boolean
}

const withPlaceholder = ({
  theme,
  value
}: {
  theme: ThemeType
  value: Props['value']
}) =>
  value === '' &&
  css`
    color: ${theme.colors.input.default.placeholder};
  `

const withVerticalPadding = ({
  theme,
  sizeVariant
}: {
  theme: ThemeType
  sizeVariant: InputBaseSize
}) => {
  switch (sizeVariant) {
    case 'sm':
      return css`
        padding-top: ${theme.layout.spacing.s250}px;
        padding-bottom: ${theme.layout.spacing.s250}px;
      `

    case 'md':
      return css`
        padding-top: ${theme.layout.spacing.s300}px;
        padding-bottom: ${theme.layout.spacing.s300}px;
      `

    case 'lg':
      return css`
        padding-top: ${theme.layout.spacing.s400}px;
        padding-bottom: ${theme.layout.spacing.s400}px;
        line-height: ${theme.fonts.lineHeight.sm} !important;
      `

    default:
      return null
  }
}

export const InputSelectElement = styled('select')<{
  showInvalid?: boolean
  showValid?: boolean
  withStartIcon?: boolean
  withEndIcon?: boolean
  sizeVariant: InputBaseSize
  textTransformType: Props['textTransformType']
  isRounded: Props['isRounded']
  value: Props['value']
}>(
  appearanceNone,
  baseLayout,
  withSize,
  withBorder,
  withBackground,
  withInvalidState,
  withShadows,
  withPlaceholder,
  withIconPadding,
  withRadius,
  withDisabled,
  withTextTransform,
  withTypography,
  withVerticalPadding,
  () => css`
    z-index: 0;
  `
)

export const InputSelect = ({
  autoComplete,
  caption,
  children,
  dataId,
  disabled,
  hasError,
  icon,
  id,
  isRounded,
  isTouched,
  name,
  onBlur,
  onChange,
  onClick,
  placeholder,
  setFieldValue,
  size = 'lg',
  textTransformType = TextTransform.none,
  value,
  withValidationIcon = false,
  className
}: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const showInvalid = isTouched && hasError
  const showValid = isTouched && !hasError

  const handleBlur = (event: React.FocusEvent<HTMLSelectElement>) => {
    setIsOpen(false)
    if (onBlur) {
      onBlur(event)
    }
  }

  const handleChange = (event: ChangeEvent) => {
    const newValue = (event.currentTarget as HTMLSelectElement).value

    setIsOpen(false)

    if (setFieldValue && name) {
      setFieldValue(name, newValue)
    } else if (onChange) {
      onChange(newValue)
    }
  }

  const handleClick = () => {
    if (onClick) onClick()
    setIsOpen(true)
  }

  return (
    <InputWrapper className={className}>
      <InputSelectElement
        autoComplete={autoComplete}
        data-field-error={hasError}
        data-id={dataId}
        disabled={disabled}
        id={id}
        isRounded={isRounded}
        name={name}
        value={value}
        showInvalid={showInvalid}
        showValid={showValid}
        sizeVariant={size}
        textTransformType={textTransformType}
        withStartIcon={Boolean(icon)}
        onBlur={handleBlur}
        onChange={handleChange}
        onClick={handleClick}
      >
        {placeholder && (
          <option disabled hidden value="">
            {placeholder}
          </option>
        )}
        {children}
      </InputSelectElement>

      <StartIconsWrapper sizeVariant={size}>{icon}</StartIconsWrapper>
      <EndIconsWrapper isRounded={isRounded} sizeVariant={size}>
        {withValidationIcon ? (
          <ValidationBadge showInvalid={showInvalid} showValid={showValid} />
        ) : null}
        <CaratWrapper isOpen={isOpen} disabled={disabled} sizeVariant={size}>
          <Icon name="CaratUp" size="xs" />
        </CaratWrapper>
      </EndIconsWrapper>

      {caption && (
        <CaptionWrapper disabled={disabled} showInvalid={showInvalid}>
          <Text variant="labelXS">{caption}</Text>
        </CaptionWrapper>
      )}
    </InputWrapper>
  )
}
