import {useEffect, useState} from 'react'

import {getWindowSize} from '../../window/getWindowSize'

export interface Size {
  /** The width of the viewport */
  width: number
  /** The height of the viewport */
  height: number
}

/**
 *
 * This hook will return the innerWidth and innerHeight of the viewport.
 * The hook will trigger a re-render on window.resize as well
 * @returns An object with the window dimensions with a [[Size]] type
 * @example
 * ```typescript
 * const sizes = useWindowSize()
 * console.log(sizes) // {width: 128, height:360}
 * ```
 */
function useWindowSize(): Size {
  const [size, setSize] = useState(getWindowSize())

  useEffect(() => {
    const handleResize = () => {
      // Ignore zoom-in events, we want to keep the layout stable for zooming
      // and only re-layout when the viewport actually changes (ie. rotated or navigation bars shown/hidden)
      const isZoomedIn = Boolean(window.innerWidth < window.outerWidth)
      if (isZoomedIn) return

      setSize(getWindowSize())
    }

    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return size
}

export default useWindowSize
