import React, {useContext, useEffect, useRef, useState} from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {useTheme} from '@emotion/react'

import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {PopoverPlacement} from '@daedalus/atlas/Popover'
import {Text} from '@daedalus/atlas/Text'
import {getIsBrandKiwi} from '@daedalus/core/src/brand/modules/selectors'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {ConfigureI18nContext} from '@daedalus/core/src/localization/components/ConfigureI18n'
import {
  dateStringToMiddayDate,
  getEarliestCheckInDate
} from '@daedalus/core/src/utils/date'

import {FormButton, IconWrapper} from '../FormButton'
import {DatePickerOverlay} from './DatePickerOverlay'
import {DatePickerPopover} from './DatePickerPopover'

export const datePickerMessages = defineMessages({
  nights: {
    id: 'nights',
    defaultMessage: '{count} {count, plural, one {night} other {nights}}'
  }
})

interface Props {
  checkIn: string
  checkOut: string
  openedDatePickerType: DatePickerType | null
  isOverlay?: boolean
  inputSize?: 'md' | 'lg'
  isLoading?: boolean
  onSetOpenedDatePickerType: (type: DatePickerType | null) => void
  onInputClick?: (type: DatePickerType) => void
  onUpdate: (checkInDate: string, checkOutDate: string) => void
  onClose?: () => void
  onSubmit?: (checkInDate: string, checkOutDate: string) => void
  onOverlayCheckOutSelected?: () => void
  isCompact?: boolean
}

export const DatePicker = ({
  checkIn,
  checkOut,
  openedDatePickerType,
  isOverlay,
  inputSize = 'md',
  isLoading,
  onSetOpenedDatePickerType,
  onUpdate,
  onClose,
  onSubmit,
  onInputClick,
  onOverlayCheckOutSelected,
  isCompact
}: Props) => {
  const {formatDate} = useIntl()
  const brandIsKiwi = useSelector(getIsBrandKiwi)
  const theme = useTheme()

  const overlayRef = useRef<HTMLDivElement>(null)
  const popoverRef = useRef<HTMLDivElement>(null)

  const [selectedCheckIn, setSelectedCheckIn] = useState('')
  const [selectedCheckOut, setSelectedCheckOut] = useState('')

  const {monthNames, weekDayNamesShort, firstDayOfWeek} =
    useContext(ConfigureI18nContext)

  useEffect(() => {
    if (checkIn && !selectedCheckIn) {
      setSelectedCheckIn(checkIn)
    }
  }, [checkIn, selectedCheckIn])

  useEffect(() => {
    if (checkOut && !selectedCheckOut) {
      setSelectedCheckOut(checkOut)
    }
  }, [checkOut, selectedCheckOut])

  const checkInDate =
    checkIn.length > 0 &&
    formatDate(dateStringToMiddayDate(selectedCheckIn), {
      day: '2-digit',
      month: 'short'
    })

  const checkOutDate =
    checkOut.length > 0 &&
    formatDate(dateStringToMiddayDate(selectedCheckOut), {
      day: '2-digit',
      month: 'short'
    })
  const earliestCheckInDate = getEarliestCheckInDate()

  const handleDatePickerOpen = (datePickerType: DatePickerType) => {
    onSetOpenedDatePickerType(datePickerType)
  }

  const handleDateChange = (checkInDate: string, checkOutDate: string) => {
    setSelectedCheckIn(checkInDate)
    setSelectedCheckOut(checkOutDate)
  }

  const onDatePickerCloseClick = () => {
    onClose?.()

    setSelectedCheckIn(checkIn)
    setSelectedCheckOut(checkOut)
    onUpdate(checkIn, checkOut)

    onSetOpenedDatePickerType(null)
  }

  const onDatePickerSubmitClick = () => {
    onSubmit?.(selectedCheckIn, selectedCheckOut)

    onUpdate(selectedCheckIn, selectedCheckOut)

    onSetOpenedDatePickerType(null)
  }

  const onDatePickerInputClick = (datePickerType: DatePickerType) => {
    onSetOpenedDatePickerType(datePickerType)
    onInputClick && onInputClick(datePickerType)
  }

  return (
    <>
      <Grid spacing="s300" container justify="flex-start" wrap="nowrap">
        <Grid mobileSm={6} desktopSm={6}>
          <FormButton
            size={inputSize}
            id="CheckInInput"
            dataId="CheckInInput"
            onClick={() => onDatePickerInputClick(DatePickerType.CheckIn)}
            icon={
              <IconWrapper>
                <Icon name="CalendarStart" />
              </IconWrapper>
            }
            isFocused={openedDatePickerType === DatePickerType.CheckIn}
            borderRadius={brandIsKiwi ? theme.layout.radius.sm : undefined}
          >
            <Text variant="bodyS">{checkInDate}</Text>
          </FormButton>
        </Grid>

        <Grid mobileSm={6} desktopSm={6}>
          <FormButton
            size={inputSize}
            id="CheckOutInput"
            dataId="CheckOutInput"
            onClick={() => onDatePickerInputClick(DatePickerType.CheckOut)}
            icon={
              <IconWrapper>
                <Icon name="CalendarEnd" />
              </IconWrapper>
            }
            isFocused={openedDatePickerType === DatePickerType.CheckOut}
            borderRadius={brandIsKiwi ? theme.layout.radius.sm : undefined}
          >
            <Text variant="bodyS">{checkOutDate}</Text>
          </FormButton>
        </Grid>
      </Grid>

      <div ref={overlayRef} />
      <div ref={popoverRef} />

      {isOverlay ? (
        <DatePickerOverlay
          containerRef={overlayRef}
          isOpen={!!openedDatePickerType}
          checkIn={selectedCheckIn}
          checkOut={selectedCheckOut}
          months={monthNames}
          firstDayOfWeek={firstDayOfWeek}
          weekdaysShort={weekDayNamesShort}
          openedDatePickerType={openedDatePickerType}
          earliestCheckInDate={earliestCheckInDate}
          onChange={handleDateChange}
          onDatePickerOpen={handleDatePickerOpen}
          onCheckOutSelected={onOverlayCheckOutSelected}
          onSubmit={onDatePickerSubmitClick}
          onClose={onDatePickerCloseClick}
          isLoading={isLoading}
        />
      ) : (
        <DatePickerPopover
          containerRef={popoverRef}
          isOpen={!!openedDatePickerType}
          checkIn={selectedCheckIn}
          checkOut={selectedCheckOut}
          placement={PopoverPlacement.BottomLeft}
          verticalOffset={8}
          months={monthNames}
          firstDayOfWeek={firstDayOfWeek}
          weekdaysShort={weekDayNamesShort}
          openedDatePickerType={openedDatePickerType}
          earliestCheckInDate={earliestCheckInDate}
          onChange={handleDateChange}
          onDatePickerOpen={handleDatePickerOpen}
          onSubmit={onDatePickerSubmitClick}
          onOutsideClick={onDatePickerCloseClick}
          isLoading={isLoading}
          isCompact={isCompact}
        />
      )}
    </>
  )
}
